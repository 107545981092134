import * as React from 'react';
import { MyMoney } from '@waves/balances/src/utils';
import { HTMLAttributes } from 'react';
import { AssetLogo } from '../../AssetLogo/AssetLogo';

interface DropdownProps extends HTMLAttributes<any> {
    balance: MyMoney;
    onSelectItem?: (balance: MyMoney) => void;
    inInput?: boolean;
}

export const DropdownItem: React.FC<DropdownProps> = ({ balance, onSelectItem, className, inInput = false }) => (
    <li className={`search-input-dropdown__item ${className}`} key={balance.asset.id} onClick={() => {
        if (onSelectItem) {
            onSelectItem(balance)
        }
    }}>
        <div className='search-input-dropdown__meta-wrapper'>
            {balance.asset.icon ?
                <span className='search-input-dropdown__asset-icon' style={{ backgroundImage: `url('${balance.asset.icon}')` }} /> :
                <AssetLogo asset={balance.asset} />}
            <span className='search-input-dropdown__asset-ticker'>{balance.asset.ticker || balance.asset.name}</span>
            <span className='search-input-dropdown__asset-name'>{balance.asset.displayName}</span>
        </div>
        {!inInput && <div className='search-input-dropdown__asset-balance'>
            {balance.toFormat()}
        </div>}
    </li>
);
