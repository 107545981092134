import * as React from 'react';
import { TxFormWrapper } from '../TxFormWrapper/TxFormWrapper';
import { SearchAsset } from '../SearchAsset/SearchAsset';
import { MyMoney } from '@waves/balances/src/utils';
import { Signer } from '@waves/signer';
import { useParseTransfers } from './useParseTransfers';
import { Button } from '../Button/Button';
import { Pending } from '../Pending/Pending';
import { PlateError } from '../Error/PlateError';
import { libs } from '@waves/waves-transactions';
import { useImportFile } from '../../shared/useImportFile';
import { readFile } from '../../shared/readFile';
import './MasstransferForm.scss';

export interface ITransferItem {
    recipient: string;
    amount: MyMoney;
}

const TEXTAREA_PLACEHOLDER = 'Use a comma to separate recipient and the amount to send. Use\n' +
    'a new line for each recipient. \n' +
    'Example:\n' +
    'Address1,Amount1\n' +
    'Alias2,Amount2'

interface MassTransferFormProps {
    handleLogout: () => void;
    balances: Record<string, MyMoney>;
    signer: Signer;
    balancePending: boolean;
}

const getSendingSumm = (transfers: ITransferItem[]) => {
    return transfers.map(bal => bal.amount).reduce((prev, acc) => {
        return acc.add(prev);
    }, transfers[0].amount.cloneWithTokens(0));
};

export const MassTransferForm: React.FC<MassTransferFormProps> = ({ handleLogout, balances, signer, balancePending }) => {
    const [selectedAsset, setAsset] = React.useState<MyMoney>();
    const [inputValue, setInputValue] = React.useState('');
    const [descValue, setDescValue] = React.useState('');
    const [error, setError] = React.useState('');


    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();

        setInputValue(e.target.value);
        setError('');
    }, []);

    const handleDescChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();

        setDescValue(e.target.value);
    }, []);

    const onSelect = React.useCallback((balance: MyMoney) => {
        setError('');
        setAsset(balance)
    }, []);

    const handleError = ({ data }) => {
        if (data.status === 'ok') {
            readFile(data.file).then(result => {
                setInputValue(result);
            }).catch(e => {
                setError(e);
            });
        } else {
            setError('Wrong file extension')
        }
    }

    const { onClick } = useImportFile(handleError);

    const { transfers } = useParseTransfers(inputValue.trim(), selectedAsset);

    const handleConfirm = React.useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setError('');
        if (transfers.length > 100) {
            setError('Any recipients after the first 100 will be ignored.')
        }

        if (transfers.length && getSendingSumm(transfers).gt(balances[selectedAsset.asset.id])) {
            setError('Insufficient funds.')
            return;
        }

        try {
            const tx = await signer.massTransfer({
                transfers: transfers.map(item => ({ ...item, amount: item.amount.getCoins().toNumber() })),
                assetId: selectedAsset.asset.id,
                attachment: libs.crypto.base58Encode(libs.crypto.stringToBytes(descValue))
            }).broadcast();

            // @ts-ignore
            await signer.waitTxConfirm(tx, 0);

            console.log('%c DONE', 'color: green');
        } catch (e) {
            setError(JSON.stringify(e, null, 4));
        }

    }, [selectedAsset, signer, transfers, descValue]);

    const isDisabledButton = React.useMemo(
        () => transfers.length === 0 || balancePending,
        [transfers, balancePending]
    );

    const clearForm = () => {
        setError('');
        setInputValue('');
        setDescValue('');
        setAsset(null);
    }

    return <TxFormWrapper title={'Mass Transfer'} handleLogout={() => {
        handleLogout();
        clearForm();
    }} onConfirm={handleConfirm} confirmText='Transfer'>

        <div className='form__row'>
            <label className='form__label'>Select Asset</label>
            {balancePending ?
                <Pending /> :
                <SearchAsset balances={balances} onSelect={onSelect} selected={selectedAsset}/>
            }

        </div>

        <div className='form__row'>
            <div className='space-between'>
                <label className='form__label'>Recipients, Amounts: {transfers.length}/100</label>
                <span className='link' onClick={onClick}>Import CSV File</span>
            </div>
            <textarea className='input textarea' placeholder={TEXTAREA_PLACEHOLDER} onChange={handleChange} value={inputValue}/>
        </div>

        <div className='form__row'>
            <label className='form__label'>Description</label>
            <textarea className='input textarea' placeholder={'Write a message'} onChange={handleDescChange} value={descValue} />
        </div>

        {error && <PlateError>
            {error}
        </PlateError>}

        <Button className='big confirm-btn' variant='primary' onClick={handleConfirm} disabled={isDisabledButton}>Transfer</Button>
    </TxFormWrapper>
}
