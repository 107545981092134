export const readFile = (fileBlob: File): Promise<string> => {
    const reader = new FileReader();
    const promise = new Promise((resolve, reject) => {
        reader.addEventListener('error', (error) => {
            reject(error);
        });
        reader.addEventListener('abort', (event) => {
            reject(event);
        });
        reader.addEventListener('load', () => {
            resolve(reader.result);
        }, false);
        reader.readAsBinaryString(fileBlob);
    })


    return promise as Promise<string>;
}
