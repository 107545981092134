import { useEffect, useRef } from 'react';


const createInput = (): HTMLInputElement => {

    const input = document.createElement('input');
    input.type = 'file';
    input.style.position = 'absolute';
    input.style.left = '-100px';
    input.style.top = '-100px';
    document.body.appendChild(input);
    return input;
}


const isFileValid = (file): boolean => {
    const fileExtension = file.name.split('.')[1];
    return fileExtension && fileExtension === 'csv';
}

export const useImportFile = (onChangeProp: (data) => void) => {
    const input = useRef(null);

    useEffect(() => {
        const onChange = () => {
            const [file] = input.current.files;

            if (file) {
                if (!isFileValid(file)) {
                    onChangeProp({
                        data: {
                            status: 'error',
                            type: 'extension',
                            message: 'Wrong extension',
                            file
                        }
                    });
                    return null;
                }

                onChangeProp({ data: { status: 'ok', file } });
            }

            input.current.value = '';
        }

        input.current = createInput();

        input.current.addEventListener('change', () => onChange());


        return () => {
            document.body.removeChild(input.current);
        }
    }, [onChangeProp]);


    return {
        onClick: () => {
            input.current.click();
        }
    }
}
